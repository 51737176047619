import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'signPositiveNegative'
})
export class SignPositiveNegativePipe implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe) {
  }

  transform(value: number): any {
    var decimalPlacesValue = this._decimalPipe.transform(value, "1.2-2", "en");
    return value > 0 ? `(+${decimalPlacesValue})` : `(${decimalPlacesValue})`;
  }
}
