<nav class="navbar navbar-inner navbar-fixed-top">
    <div class="container">
        <div class="navbar-header">
            <!-- <button type="button" class="navbar-toggle" 
                    (click)="isCollapsed = !isCollapsed">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button> -->
            <!-- <a class="navbar-brand" routerLink="/customers">
                <img src="assets/images/people.png" alt="logo" />
                <span class="app-title">Customer Manager</span>
            </a> -->
            <!-- <span class="navbar-collapse" [attr.data-collapse]="isCollapsed">
                <ul class="nav navbar-nav nav-pills navBarPadding">
                    <li routerLinkActive="active"><a routerLink="/customers">Customers</a></li>
                    <li routerLinkActive="active"><a routerLink="/orders">Orders</a></li>
                    <li routerLinkActive="active"><a routerLink="/about">About</a></li>
                </ul>
            </span> -->
        </div>
    </div>
</nav>