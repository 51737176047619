import { Pipe, PipeTransform } from '@angular/core';
import { ISerie } from '../models/seriesInfo.interfaces';

@Pipe({
  name: 'directionCompassRose',
})
export class DirectionCompassRose implements PipeTransform {
  constructor() {}

  transform(serie: ISerie | number, isCurrents?: boolean): any {
    let targetAngle: number;
  
    // Check if the value is an object with lastValue or a direct number
    targetAngle = typeof serie === "number" ? serie : serie.lastValue; 
    
    let compassRoseAngles = {
      'N': isCurrents ? 180 : 0,
      'S': isCurrents ? 0 : 180,
      'E': isCurrents ? 270 : 90,
      'O': isCurrents ? 90 : 270,
      'NE': isCurrents ? 225 : 45,
      'NO': isCurrents ? 135 : 315,
      'SE': isCurrents ? 315 : 135,
      'SO': isCurrents ? 45 : 225,
      'NNE': isCurrents ? 202.5 : 22.5,
      'ENE': isCurrents ? 247.5 : 67.5,
      'ESE': isCurrents ? 292.5 : 112.5,
      'SSE': isCurrents ? 337.5 : 157.5,
      'SSO': isCurrents ? 22.5 : 202.5,
      'OSO': isCurrents ? 67.5 : 247.5,
      'ONO': isCurrents ? 112.5 : 292.5,
      'NNO': isCurrents ? 157.5 : 337.5
    };

    let smallestDifference = Infinity;
    let closestKey: string = '';
    
    for (let key in compassRoseAngles) {
      let angle = compassRoseAngles[key]
      let diff = Math.min(Math.abs(angle - targetAngle), 360 - Math.abs(angle - targetAngle));

      if (diff < smallestDifference) {
        smallestDifference = diff;
        closestKey = key;
      }
    }

    return closestKey;
  }
}
