import { Component, Inject, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  tabIconImg: string = `assets/${environment.project}/tab_icon.png`;

  backgroundImg: string = `url(assets/${environment.project}/background.jpg) no-repeat center`;
  backgroundSize: string = 'cover';

  // https://onthecode.co.uk/monitoring-live-angular-apps-with-azure-application-insights/

  constructor(@Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title) {
      this.titleService.setTitle(`${environment.pageTile}`);
    }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    //dynamically change the tab icon of the page depending on the project
    this._document
      .getElementById('appFavicon')
      .setAttribute('href', this.tabIconImg);
  }
}
