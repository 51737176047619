import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PreloadModulesStrategy } from './core/strategies/preload-module.strategy';

const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: '/main' },
  // { path: 'main', loadChildren: () => import('src/app/main/main.module').then(m => m.MainModule) },
  {
    path: '',
    loadChildren: () =>
      import('src/app/main/main.module').then((m) => m.MainModule),
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }, // catch any unfound routes and redirect to home page
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadModulesStrategy,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
  providers: [PreloadModulesStrategy],
})
export class AppRoutingModule {}
