import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tideEvent'
})
export class TideEventPipe implements PipeTransform {
  transform(value: number): string {
    return value >= 2 ? 'Preia-Mar' : 'Baixa-Mar';
  }
}
