import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MaterialModule } from '../helperModules/material.module';
import { SignPositiveNegativePipe } from './pipes/sign-positive-negative.pipe';
import { TideEventPipe } from './pipes/tide-event.pipe';
import { DayOfWeekPipe } from './pipes/day-of-week.pipe';
import { DirectionCompassRose } from './pipes/direction-compass-rose.pipe';
import { GetSerieValuePipe } from './pipes/get-serie-value.pipe';

@NgModule({
  declarations: [
    SignPositiveNegativePipe,
    DirectionCompassRose,
    TideEventPipe,
    DayOfWeekPipe,
    GetSerieValuePipe,
  ],
  imports: [CommonModule, MaterialModule],
  exports: [
    CommonModule,
    MaterialModule,
    SignPositiveNegativePipe,
    DirectionCompassRose,
    TideEventPipe,
    DayOfWeekPipe,
    GetSerieValuePipe
  ],
  providers: [DecimalPipe],
})
export class SharedModule {}
