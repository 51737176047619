import { Pipe, PipeTransform } from '@angular/core';
import { ISerie } from '../models/seriesInfo.interfaces';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'getSerieValue'
})
export class GetSerieValuePipe implements PipeTransform {

  constructor(private _decimalPipe: DecimalPipe) {}
  
  transform(serie: ISerie, decimalPlaces?: number, customUnit?: string): string {
    let decimalPlacesValue: string;
    decimalPlacesValue = decimalPlaces !== undefined
      ? this._decimalPipe.transform(serie.lastValue, `1.${decimalPlaces}-${decimalPlaces}`, 'en')
      : this._decimalPipe.transform(serie.lastValue, '1.1-1', 'en');

    if (decimalPlacesValue === undefined) {
      return ` -`
    }

    let unit = serie.unit;
    if (serie.unit !== 'º') {
      unit = ` ${unit}`;
    }

    return customUnit
      ? `${decimalPlacesValue} ${customUnit}`
      : `${decimalPlacesValue}${unit}`;
  }
}
