// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  project: 'apsssetubal',
  sharingAvailable: true,
  pageTile: 'Meteorologia - Porto de Setúbal',
  stations: [    
    {
      project: 'apsstremnaval',
      name: 'Trem Naval',
      url: 'https://apss.hidromod.com/meteoapss/tremnaval/#/',
    },
    {
      project: 'apsstroia',
      name: 'Tróia',
      url: 'https://apss.hidromod.com/meteoapss/troia/#/',
    },
    {
      project: 'apsssesimbra',
      name: 'Sesimbra',
      url: 'https://apss.hidromod.com/meteoapss/sesimbra/#/',
    },
    {
      project: 'apsslisnave',
      name: 'Lisnave',
      url: 'https://apss.hidromod.com/meteoapss/lisnave/#/',
    },
    {
      project: 'apssclubenaval',
      name: 'Clube Naval',
      url: 'https://apss.hidromod.com/meteoapss/clubenaval/#/',
    },
    {
      project: 'apssbaliza2',
      name: 'Baliza 2',
      url: 'https://apss.hidromod.com/meteoapss/baliza2/#/',
    }
  ],
};
